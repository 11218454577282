import api from "@basic/api";
import auth from "@basic/auth";
import store from "./store";
import Vue from "vue";

let errorMapper = {
	"1001": {
		"403001": "验证码错误 ，请核对！"
	}
}

const http = api.http(process.env.VUE_APP_VISITOR, {
	failure: (err) => {
		let sysCode = err.sys_code.toString();
		let errCode = err.err_code.toString();
		let errDesc = errorMapper[sysCode][errCode];
		Vue.prototype.$notify({message: errDesc ? errDesc : "系统繁忙 ，请稍后重试！", type: "danger"});
		return Promise.reject(err);
	}
});

export default new auth(store, http, "v1/auth/guest", "v1/auth/login", "v1/auth/refresh")
