import Index from "../pages/ucenter/Index";
import Star from "../pages/ucenter/Star";
import Reward from "../pages/ucenter/Reward";
import Search from "../pages/ucenter/Search";
import Helper from "../pages/Helper";

export default [
	{
		path: "index",
		name: "UCenter.Index",
		component: Index,
	},
	{
		path: "star",
		name: "UCenter.Star",
		component: Star,
	},
	{
		path: "reward",
		name: "UCenter.Reward",
		component: Reward,
	},
	{
		path: "search",
		name: "UCenter.Search",
		component: Search,
	},
	{
		path: "helper",
		name: "UCenter.Helper",
		component: Helper
	}
];
