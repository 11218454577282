<template>
  <div class="live">
    <Loading v-if="loading"/>
    <template v-if="album.id">
      <transition name="van-fade">
        <Startup v-if="!ready" :config="album.config['startup']" @closed="()=>this.ready=true"/>
      </transition>
      <div :class="{hidden: !ready}">
        <router-view :album="album" :key="key" v-bind="$attrs"/>
      </div>
    </template>
  </div>
</template>

<script>
import api from "../utils/api";
import log from "../utils/log";
import Loading from "../comps/Loading";
import Startup from "./live/comps/Startup";
import DelayLoading from "../comps/DelayLoading";

export default {
  name: "Live",
  mixins: [DelayLoading],
  components: {Startup, Loading},
  data() {
    return {
      album: {},
      ready: this.$route.name !== "Live.Index",
    }
  },
  computed: {
    key() {
      return this.$route.fullPath;
    }
  },
  mounted() {
    const self = this;
    let albumId = this.$route.params.id;
    let preview = this.$route.query.preview;
    this.withLoading(600, () => {
      return api.v1.album.detail(albumId, preview);
    }).then(res => {
      self.album = res;
      document.title = res.info.name;
    });
    log.image("album.visited", {album_id: albumId});
  }
}
</script>

<style scoped lang="less">
.live {
  width: 100%;
  height: 100%;
  max-width: 720px;
  background: #000;
}

.hidden {
  visibility: hidden;
}
</style>
