<template>
  <div class="star">
    <NavBar title="我的收藏"/>
    <LoadingList :load-func="load" empty-text="暂无收藏照片">
      <template slot-scope="{ result }">
        <van-row :gutter="4">
          <template v-for="(star, index) in result.data">
            <van-col span="6" :key="star.id">
              <ResourceImg :id="star['photo']['thumb_id']"
                           width="100%" resize="m_8,w_120,h_120" @click="preview(result, index)"/>
            </van-col>
          </template>
        </van-row>
      </template>
    </LoadingList>
  </div>
</template>

<script>
import api from "../../utils/api";
import func from "../../utils/func";
import NavBar from "./comps/NavBar";
import {ImagePreview} from 'vant';
import LoadingList from "../../comps/LoadingList";
import ResourceImg from "../../comps/ResourceImg";

export default {
  name: "Star",
  components: {NavBar, ResourceImg, LoadingList},
  methods: {
    load(page_no) {
      let args = {
        page_no: page_no,
        page_size: 60,
      };
      return api.v1.star.search(args);
    },
    preview(result, index) {
      let urls = [];
      result.data.forEach(item => urls.push(func.imageUrl(item['photo']['thumb_id'])));
      ImagePreview({
        images: urls,
        startPosition: index,
      });
    }
  }
}
</script>

<style scoped>
.star {
  background: #000;
  padding-top: 48px;
}
</style>
