import Vue from "vue";
import auth from "./auth";

let errorMapper = {
	"2002": {
		"404100": "图片已不存在，无法下载！",
		"404101": "相册已不存在，无法下载！",
	},
}

export default {
	commonErr(err) {
		let sysCode = err.sys_code.toString();
		let errCode = err.err_code.toString();
		if (errCode.substr(0, 3) === "401") {
			auth.refresh()
				.catch(async () => {
					let guest = await auth.isGuest();
					guest ? window.location.reload()
						: auth.logout().finally(window.location.reload);
				});
		} else {
			let errDesc = errorMapper[sysCode][errCode];
			Vue.prototype.$notify({message: errDesc ? errDesc : "系统繁忙 ，请稍后重试！", type: "danger"});
		}
		return Promise.reject(err)
	},
	sendCodeErr(err) {
		Vue.prototype.$notify({message: "验证码发送失败，请稍后重试！", type: "danger"});
		return Promise.reject(err);
	}
}
