import api from "../utils/api";

export default {
    namespaced: true,
    state: {
        detail: {}
    },
    actions: {
        async detail(ctx, force) {
            if (ctx.state.detail.id && !force) {
                return ctx.state.detail;
            }
            ctx.state.detail = await api.v1.user.detail();
            return ctx.state.detail;
        }
    }
}
