<template>
    <van-loading
        class="loading" type="spinner" color="#ff5500"
        :vertical="true" size="48px">努力加载中
    </van-loading>
</template>

<script>
    export default {
        name: "SimpleLoading"
    }
</script>

<style scoped>
    .loading {
        top: 30%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100001;
        position: absolute;
    }

    .van-loading__text {
        font-size: 12px;
    }
</style>

