<template>
    <div class="startup" v-if="config.enable">
        <ResourceImg class="cover" :id="config['bg_image']" resize="m_4,w_1920,h_1920"/>
        <van-button v-if="ttl" class="count-down" @click="close" size="small" :hairline="true" :round="true">{{ttl}} s</van-button>
        <van-button class="enter-button" @click="close" :hairline="true" :round="true">{{config['btn_text']}}</van-button>
    </div>
</template>

<script>
    import ResourceImg from "../../../comps/ResourceImg";
    import func from "../../../utils/func";

    export default {
        name: "Startup",
        components: {ResourceImg},
        props: {config: Object},
        data: function () {
            return {
                ttl: 0, // 倒计时长
            }
        },
        mounted() {
            this.ttl = this.config.interval;
            if (!this.ttl || !this.config.enable) this.countDown();
            else func.setTimeout(1200, this.countDown);
        },
        methods: {
            close() {
                this.$emit("closed");
            },
            countDown: function () {
                let next = this.config.enable && this.ttl-- > 1;
                next ? func.setTimeout(1000, this.countDown) : this.close();
            }
        }
    }
</script>

<style scoped lang="less">
    .startup {
        width: 100%;
        height: 100%;
        z-index: 9000;
        overflow: hidden;
        position: absolute;

        .cover {
            max-width: 100%;
        }

        .count-down {
            top: 20px;
            right: 20px;
            z-index: 9100;
            color: #ffffff;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.4);
        }

        .enter-button {
            left: 0;
            right: 0;
            width: 60%;
            bottom: 50px;
            z-index: 9100;
            margin: 0 auto;
            color: #ffffff;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
</style>
