import func from "../utils/func";

export default {
    data() {
        return {
            counter: 0,
            loading: false,
        }
    },
    computed: {
        first() {
            return this.counter === 0;
        },
        firstLoading() {
            return this.loading && this.first;
        }
    },
    methods: {
        withLoading(duration, callback) {
            const self = this;
            self.setLoading(true);
            let start = new Date().getTime();
            return callback().finally(() => {
                let cost = new Date().getTime() - start;
                if (duration <= cost) self.setLoading(false);
                else return func.sleep(duration - cost).then(() => self.setLoading(false));
            });
        },
        setLoading(loading) {
            this.loading = loading;
            if (!loading) this.counter += 1;
        }
    }
}
