<template>
    <div>
        <van-button
            v-for="(item, index) in options"
            :key="index" size="small" :round="true" type="primary"
            :class="{active: active(item)}" @click="change(item.value)">
            {{item.label}}
        </van-button>
    </div>
</template>

<script>
    import StateMixin from "./StateMixin";

    export default {
        name: "ButtonTabs",
        props: {
            options: Array,
        },
        mixins: [StateMixin],
        methods: {
            change(value) {
                this.value = value;
                this.$emit("change", value);
            },
            active(item) {
                return item.value === this.value;
            },
        }
    }
</script>
