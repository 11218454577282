<template>
  <van-nav-bar class="ucenter-nav-bar" :left-arrow="true" :fixed="true" v-bind="$attrs" @click-left="back"/>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    back() {
      let noPrev = this.$router.history._startLocation === this.$route.path;
      noPrev ? this.$router.replace({name: "UCenter.Index"}) : this.$router.back();
    }
  }
}
</script>

<style lang="less">
.ucenter-nav-bar.van-nav-bar {
  color: #ffffff;
  background: #000000;

  .van-nav-bar__title {
    color: #ffffff;
  }

  .van-nav-bar__left {
    font-weight: bold;

    .van-nav-bar__arrow {
      color: #ffffff;
      font-size: 18px;
    }
  }
}

.ucenter-nav-bar.van-nav-bar--fixed {
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 720px;
}

.ucenter-nav-bar.van-nav-bar--fixed::after {
  border-color: #444;
}
</style>
