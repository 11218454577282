<template>
  <div class="index">
    <van-row class="logo" @click="toLogo">
      <van-col span="6">
        <ResourceImg v-if="user['logo']['avatar']" :id="user['logo']['avatar']" class="avatar"/>
        <van-icon v-else name="user-o" class="avatar"></van-icon>
      </van-col>
      <van-col span="16" class="info">
        <div><b>{{ user["logo"]["nick"] || "暂未设置昵称" }}</b></div>
        <div style="color:#999">{{ user["mobile"] | maskString(3,4) }}</div>
      </van-col>
    </van-row>
    <van-cell-group>
      <van-cell :to="{name:'UCenter.Star'}" title="我的收藏" icon="star-o" value="" :is-link="true"/>
      <van-cell :to="{name:'UCenter.Search'}" title="查询记录" icon="search" value="" :is-link="true"/>
      <van-cell :to="{name:'UCenter.Reward'}" title="打赏记录" icon="gold-coin-o" value="" :is-link="true"/>
    </van-cell-group>
    <van-button class="logout" @click="logout" icon="exchange" block>退出登录</van-button>
  </div>
</template>

<script>
import auth from "../../utils/auth";
import ResourceImg from "../../comps/ResourceImg";

export default {
  name: "Index",
  props: {user: Object},
  components: {ResourceImg},
  methods: {
    toLogo() {
    },
    logout() {
      auth.logout().then(() => window.location.reload());
    }
  }
}
</script>

<style scoped lang="less">
.index {
  .logo {
    height: 60px;
    padding: 16px;
    background: #222;
    font-size: 14px;
    margin-bottom: 16px;

    .avatar {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #fff;
      background: #444;
      border-radius: 100%;
    }

    .info {
      color: @textColor;
      padding: 8px 0;
      line-height: 25px;
    }
  }

  .logout {
    margin-top: 16px;
    border: none;
    color: #ffffff;
    border-radius: 0;
    background: #222222;
  }

  .van-cell-group {
    .van-cell {
      background: #222;
      color: #fff;
    }

    .van-cell::after {
      border-color: #444;
    }
  }

  .van-cell-group::after {
    border: none;
  }

  .van-icon-arrow {
    float: right;
    color: #666;
    font-size: 16px;
  }
}
</style>
