<template>
  <van-overlay class="loading" :show="true">
    <img src="../assets/logo.png" alt=""/>
    <van-loading size="100px" color="#ff5500"/>
  </van-overlay>
</template>

<script>
export default {
  name: "Loading",
}
</script>

<style scoped lang="less">
.loading {
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  background: #000000;

  img {
    width: 60px;
    position: absolute;
  }

  circle {
    stroke-width: 1;
  }
}
</style>
