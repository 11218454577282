<template>
  <div class="tab-list">
    <van-sticky>
      <StateTabs v-if="tabItems.length" v-model="tabKey" :line-height="0"
                 @ready="()=>this.ready=true" state-key="tab.key" v-on="$listeners"
                 title-active-color="#ff5500" title-inactive-color="#ffffff">
        <van-tab name="photo" title="照片"></van-tab>
        <template v-for="(item, index) in tabItems">
          <van-tab :name="item.name" :title="item.title" :key="index"></van-tab>
        </template>
      </StateTabs>
      <van-row v-if="isPhoto && folderItems.length > 1" class="folders">
        <van-col span="21">
          <ButtonTabs v-model="params.folder_id" @change="refresh" :options="folderItems"/>
        </van-col>
        <van-col span="3">
          <van-dropdown-menu :overlay="false">
            <van-dropdown-item ref="dropdown">
              <ButtonTabs class="btn-tabs" v-model="params.folder_id" @change="refresh"
                          :options="folderItems"/>
            </van-dropdown-item>
          </van-dropdown-menu>
        </van-col>
      </van-row>
      <div v-if="isPerson" class="person-tips">
        <van-icon name="flag-o"/>
        速来围观，找一找下面哪个是您 ?
      </div>
    </van-sticky>
    <div v-if="isIntro" class="intro" v-html="album['intro']"></div>
    <Photos v-if="isPhoto" ref="photos" :album="album" :params="params" :page-size="120"
            :width="width" :height="height" :padding="3" :columns="2" :scrollable="scrollable"/>
    <Persons v-if="isPerson" :album="album" :width="width" :height="height" :padding="3" :columns="4"
             :scrollable="scrollable"/>
    <Book v-if="isBook" :album="album"/>
  </div>
</template>

<script>
import Photos from "./Photos";
import Persons from "./Persons";
import Book from "./Book";
import ButtonTabs from "../../../comps/ButtonTabs";
import StateTabs from "../../../comps/StateTabs";

export default {
  name: "TabList",
  components: {StateTabs, Book, ButtonTabs, Persons, Photos},
  props: {
    album: Object,
    scrollable: Boolean,
  },
  data() {
    return {
      tabKey: "photo",
      params: {
        folder_id: "",
      },
      ready: false,
    }
  },
  computed: {
    width() {
      return Math.min(document.documentElement.clientWidth, 720);
    },
    height() {
      return document.documentElement.clientHeight;
    },
    isIntro() {
      return this.ready && this.tabKey === "intro";
    },
    isPhoto() {
      return this.ready && this.tabKey === "photo";
    },
    isPerson() {
      return this.ready && this.tabKey === "person";
    },
    isBook() {
      return this.ready && this.tabKey === "book";
    },
    tabItems() {
      let tabItems = [];
      if (this.album["intro"]) {
        tabItems.push({name: "intro", title: "详情"});
      }
      if (this.album.config.function['face_find']) {
        tabItems.push({name: "person", title: "人物"});
      }
      if (this.album.config.function['customer_book']) {
        tabItems.push({name: "book", title: "预约"});
      }
      return tabItems;
    },
    folderItems() {
      let folderItems = [{value: "", label: "全部"}];
      this.album['folders'].forEach(folder => {
        folderItems.push({value: folder.id, label: folder.info.name});
      });
      return folderItems;
    }
  },
  created() {
    this.ready = this.tabItems.length === 0;
  },
  methods: {
    refresh() {
      this.$refs.photos.refresh();
      this.$refs.dropdown.toggle(false);
    }
  }
}
</script>

<style lang="less">
.tab-list {
  border-top: 1px #666 solid;

  .van-tab {
    color: #fff;
    font-size: 16px;
    background: #222;
  }

  .van-tabs__nav {
    background: transparent;
  }

  .van-tabs__line {
    height: 0;
  }

  .van-tab--active {
    background: #000;
  }

  .van-sticky {
    margin: 0 auto;
    max-width: 720px;
    box-shadow: 0 1px 4px 2px #000;
  }

  .folders {
    width: 100%;
    height: 37px;
    overflow: hidden;
    color: #ffffff;
    background: #000;
    padding: 10px 5px;

    .active {
      background: #ff5500;
    }

    button {
      margin: 5px;
      background: transparent;
    }

    .van-popup {
      width: 100%;
      padding: 9px 5px;
      background: #000;
      margin-top: -47px;

      .btn-tabs {
        float: left;
        width: 87.5%;
      }
    }

    .van-dropdown-menu__bar {
      height: 37px;
      background: #000;
      box-shadow: none;
    }

    .van-dropdown-menu__title {
      padding: 0;
    }

    .van-dropdown-menu__title::after {
      border-width: 5px;
    }

    .van-dropdown-item {
      margin: 0 auto;
      max-width: 720px;
      z-index: 2007;
    }
  }

  .person-tips {
    color: #ffffff;
    background: #000;
    padding: 10px 15px;

    .van-icon {
      float: left;
      font-size: 18px;
      margin: 2px 5px 0 0;
    }
  }

  .intro {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
