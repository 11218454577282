<template>
  <div class="match">
    <NavBar :title="title"/>
    <van-loading
        v-if="loading" type="spinner" style="padding: 100px 0"
        size="60px" color="#ff5500" :vertical="true">努力加载中
    </van-loading>
    <div v-else>
      <div style="color:#fff;padding:10px 15px;">
        <van-icon name="info-o" style="float:left;margin:2px 2px 0 0;"/>
        <template v-if="bibSearch">
          没有找到您的照片？建议使用
          <span @click="openSearch" style="color:#ff5500;"><b>人脸搜索</b></span>
        </template>
        <template v-else>
          没有找到您的照片？试试使用
          <span @click="openSearch" style="color:#ff5500;"><b>号码搜索</b></span>
        </template>
      </div>
      <EfotoEmpty v-if="!photos.length" description="暂无匹配照片哦~" style="margin-top:50px;">
        <Subscribe style="margin: 0 auto;" :album="album" :template="template" :button="true"
                   @success="setSubscribe" @error="subscribeError"/>
      </EfotoEmpty>
      <template v-else>
        <VirtualCollection
            v-on="$listeners" v-bind="$attrs"
            style="overflow-x: hidden; overflow-y: scroll;"
            :collection="photos" :width="width" :height="height"
            :cellSizeAndPositionGetter="cellSizeAndPositionGetter">
          <template slot="cell" slot-scope="props">
            <LogoImage @click="onPreview(props.data)" :key="props.data.id"
                       :id="props.data.id" :src="props.data.thumb.url" :select="select" :selected="selected"/>
            <Advert :data="props.data.advert"/>
            <Subscribe v-if="props.data.isLast"
                       :album="album" :template="template" style="margin-top: 3px;display: block;"
                       :style="{height: photoWidth*2/3+'px', width: photoWidth+'px'}"
                       @success="setSubscribe" @error="subscribeError"/>
          </template>
        </VirtualCollection>
      </template>
    </div>
    <!--图片预览-->
    <Preview ref="preview" :album="album" :photos="[{group: photos}]"/>
    <FixedFunc ref="fixedFunc" :album="album" :selected="selected" :download="true" @select="onSelect"
               @selectAll="onSelectAll"/>
  </div>
</template>

<script>
import api from "../../utils/api";
import log from "../../utils/log";
import enums from "../../utils/enums";
import FixedFunc from "./comps/FixedFunc";
import DelayLoading from "../../comps/DelayLoading";
import WechatJSSDK from "../../comps/WechatJSSDK";
import LogoImage from "../../comps/LogoImage";
import func from "../../utils/func";
import Preview from "./comps/Preview";
import NavBar from "./comps/NavBar";
import DingTalkJSSDK from "../../comps/DingTalkJSSDK";
import Advert from "./comps/Advert";
import Subscribe from "./comps/Subscribe";
import EfotoEmpty from "../../comps/EfotoEmpty";

export default {
  name: "Match",
  components: {EfotoEmpty, Subscribe, Advert, NavBar, Preview, LogoImage, FixedFunc},
  mixins: [DelayLoading, WechatJSSDK, DingTalkJSSDK],
  props: {album: Object},
  data() {
    return {
      photos: [],
      columns: 2,
      padding: 3,
      offsetY: [],
      select: {
        limit: 0,
        enable: false
      },
      selected: [],
      template: "9jOTvOOoBjvD77uPFkrBJLIaEACyOQ4vTmdjkzsJhdM"
    }
  },
  computed: {
    title() {
      return `共匹配 ${this.photos.length} 张图片`;
    },
    width() {
      return Math.min(document.documentElement.clientWidth, 720);
    },
    height() {
      return document.documentElement.clientHeight - 44 - 40;
    },
    photoWidth() {
      let padding = (this.columns - 1) * this.padding;
      let noScrollWidth = this.width - func.getScrollWidth();
      return (noScrollWidth - padding) / this.columns;
    },
    bibSearch() {
      return parseInt(this.$route.params.type) === 1;
    },
  },
  mounted() {
    this.refresh();
    let cfg = {
      title: this.album["config"]["share"]["title"],
      desc: "嗨！小伙伴们速来围观，快看看我在人海中找到了谁^_^",
      cover: this.album["config"]["share"]["cover"],
    };
    let shared = (flag, url) => {
      log.image("album.shared", {album_id: this.album.id, flag: flag, url: url});
    };
    this.share(cfg, shared);
    this.shareDingTalk(cfg, shared);
  },
  methods: {
    refresh() {
      for (let i = 0; i < this.columns; i++)
        this.offsetY[i] = 0;
      this.loadPhotos();
    },
    goBack() {
      this.$router.replace({name: "Live.Index", params: {id: this.album.id}});
    },
    onPreview(photo) {
      this.$refs.preview.open(0, photo.index);
    },
    onSelect(enable, limit) {
      this.select.limit = limit;
      this.select.enable = enable;
      if (!enable) this.selected = [];
    },
    onSelectAll(enable) {
      if (!enable) this.selected = [];
      else this.selected = func.getByKey(this.photos, "id");
    },
    loadPhotos() {
      const self = this;
      const {type, value} = this.$route.params;
      this.withLoading(500, () => {
        return api.v1.album.search.execute(this.album.id, type, value)
            .then(res => {
              res.forEach((photo, index) => {
                photo.index = index;
                photo.isLast = (res.length === index + 1);
                self.photos.push(self.preparePhoto(photo, index));
              });
            });
      });
    },
    openSearch() {
      this.$refs.fixedFunc.openSearch();
    },
    preparePhoto(photo, index) {
      let columnIndex = this.offsetY.indexOf(Math.min(...this.offsetY));

      // 插入广告
      let advertHeight = 0;
      if (index % 10 === 0) {
        let i = Math.floor(index / 10) % this.album['adverts'].length;
        photo.advert = this.album['adverts'][i];
        if (photo.advert) {
          let extend = photo.advert.config.extend;
          advertHeight = parseInt(extend.height) / parseInt(extend.width) * this.photoWidth + 44;
          if (photo.advert.config.desc) advertHeight += 41;
        }
      }

      let subscribeHeight = 0;
      if (photo.isLast) subscribeHeight = this.photoWidth * 2 / 3;

      let photoHeight = (photo.height / photo.width) * this.photoWidth + advertHeight + subscribeHeight;
      this.offsetY[columnIndex] += photoHeight + this.padding;

      return {
        id: photo.id,
        data: photo,
        width: this.photoWidth,
        height: photoHeight - advertHeight - subscribeHeight,
        x: columnIndex * (this.photoWidth + this.padding),
        y: this.offsetY[columnIndex] - photoHeight
      }
    },
    cellSizeAndPositionGetter(item) {
      return {width: item.width, height: item.height, x: item.x, y: item.y}
    },
    setSubscribe() {
      const {type, value} = this.$route.params;
      this.$store.dispatch("user/detail")
          .then(res => {
            api.v1.album.search.notice.weixin(this.album.id, {
              info: {album_id: this.album.id, type: parseInt(type), value: value},
              wei_xin: {
                app_flag: enums.WechatFlag,
                open_id: res.open_ids[enums.WechatFlag],
                template: this.template,
              },
            });
          });
    },
    subscribeError(msg) {
      alert(JSON.stringify(msg));
    }
  }
}
</script>

<style lang="less">
.match {
  padding-top: 41px;
}
</style>
