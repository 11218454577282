<template>
  <BottomPopup v-model="show" :title="title">
    <div class="search">
      <van-row v-if="bibFind && faceFind" :gutter="10">
        <van-col span="10">
          <input v-model="number" class="bib-input" placeholder="请输入号码"/>
        </van-col>
        <van-col span="6">
          <van-button @click="bibSearch" icon="search" type="primary" :block="true">搜索</van-button>
        </van-col>
        <van-col span="8">
          <vue-cropper @cropped="faceSearch">
            <div class="van-button van-button--primary van-button--normal van-button--block">
              <div class="van-button__content">
                <van-icon name="scan" class="van-button__icon"/>
                <span class="van-button__text">人脸识别</span>
              </div>
            </div>
          </vue-cropper>
        </van-col>
      </van-row>
      <template v-else-if="faceFind">
        <vue-cropper @cropped="faceSearch">
          <div class="van-button van-button--primary van-button--normal van-button--block">
            <div class="van-button__content">
              <van-icon name="scan" class="van-button__icon"/>
              <span class="van-button__text">人脸识别</span>
            </div>
          </div>
        </vue-cropper>
      </template>
      <template v-else-if="bibFind">
        <van-row :gutter="10">
          <van-col span="16">
            <input v-model="number" class="bib-input" placeholder="请输入号码"/>
          </van-col>
          <van-col span="8">
            <van-button @click="bibSearch" icon="search" type="primary" :block="true">搜索</van-button>
          </van-col>
        </van-row>
      </template>
    </div>
    <div ref="history" class="history">
      <van-loading
          v-if="loading" type="spinner" style="padding:150px 0;"
          size="60px" color="#ff5500" :vertical="true">努力加载中
      </van-loading>
      <div class="items" v-else-if="bibs.total || faces.total">
        <div
            class="item" :style="{width: width+'px', height: width+'px'}"
            v-for="item in faces.data" :key="item.id" @click="toMatch(item)">
          <ResourceImg :id="avatars[item.info.value]"/>
        </div>
        <div
            class="item" :style="{width: width+'px', height: width+'px'}"
            v-for="item in bibs.data" :key="item.id" @click="toMatch(item)">
          <div style="width: 100%; text-align: center; line-height: 18px;">
            <van-icon name="user-o"/>
            <div>{{ item.info.value }}</div>
          </div>
        </div>
      </div>
      <EfotoEmpty v-else image="search" description="暂无查询记录，快去查询吧"/>
    </div>
  </BottomPopup>
</template>

<script>
import api from "../../../utils/api";
import log from "../../../utils/log";
import func from "../../../utils/func";
import ResourceImg from "../../../comps/ResourceImg";
import DelayLoading from "../../../comps/DelayLoading";
import BottomPopup from "../../../comps/BottomPopup";
import EfotoEmpty from "../../../comps/EfotoEmpty";

export default {
  name: "Search",
  components: {EfotoEmpty, BottomPopup, ResourceImg},
  mixins: [DelayLoading],
  props: {album: Object},
  data() {
    return {
      show: false,
      number: "",
      bibs: {
        total: 0,
        data: [],
      },
      faces: {
        total: 0,
        data: [],
      },
      avatars: {},
      loaded: false,
      bibFind: false,
      faceFind: false,
    }
  },
  computed: {
    width() {
      return func.computedColWidth({
        gutter: 14, count: 5, range: [60, 80],
        width: this.$refs.history.clientWidth - 32,
      })
    },
    title() {
      return this.bibFind && this.faceFind ? "请输入号码或上传头像"
          : (this.faceFind ? "请上传头像查询" : "请输入号码查询");
    }
  },
  mounted() {
    this.bibFind = this.album.config.function["bib_find"];
    this.faceFind = this.album.config.function["face_find"];
  },
  methods: {
    open() {
      this.show = true;
      this.loadHistory();
    },
    bibSearch() {
      if (!this.number) {
        return this.$notify({
          type: "danger",
          message: "请输入您的号码"
        });
      }
      if (this.number.length > 12) {
        return this.$notify({
          type: "danger",
          message: "您输入的参赛号码不正确"
        });
      }
      this.doSearch({type: 1, value: this.number});
      this.number = ""; // reset
    },
    async faceSearch(data) {
      this.loading = true;
      let part = data.split(",");
      let args = {content: part[1], public: true};
      let res = await api.v1.resource.create(args);
      this.doSearch({type: 2, value: res.id});
    },
    doSearch(args) {
      const self = this;
      self.loaded = false; // reload
      this.withLoading(500, () => {
        return api.v1.album.search
            .create(self.album.id, args)
            .then(res => self.toMatch(res));
      });
      log.image("album.search", Object.assign({album_id: self.album.id}, args));
    },
    toMatch(item) {
      let params = {
        id: this.album.id,
        type: item.info.type,
        value: item.info.value
      };
      this.$router.push({name: "Live.Match", params: params})
          .catch(() => null) // 屏蔽同地址错误
          .finally(() => this.show = false);
    },
    loadHistory() {
      const self = this;
      if (this.loaded) return;
      this.withLoading(500, async () => {
        await api.v1.album.search.history(self.album.id, 1)
            .then(res => this.bibs = res);
        await api.v1.album.search.history(self.album.id, 2)
            .then(async (res) => {
              await self.loadPersons(res);
              self.faces = res;
            });
        self.loaded = true; // 标记加载完成
      });
    },
    loadPersons(faces) {
      const self = this;
      let personIds = [];
      faces.data.forEach(item => {
        personIds.push(item.info.value);
      });
      let args = {ids: personIds.join(","), page_size: personIds.length};
      return api.v1.album.persons(this.album.id, args).then(res => self.avatars = res);
    },
  }
}
</script>

<style lang="less">
.search {
  color: #222;
  padding: 0 16px;

  .title {
    padding: 15px 0;
    font-size: 18px;
    text-align: center;
  }

  .bib-input {
    width: 100%;
    height: 40px;
    color: #000000;
    padding: 0 10px;
    border-color: #ff5500;
  }

  .van-button {
    padding: 0;
    font-size: 12px;
    border-radius: 0;
    line-height: 44px;
  }
}

.history {
  height: 400px;
  padding: 0 16px;
  overflow: hidden;
  margin-top: 8px;

  .items {
    height: 100%;
    margin: 0 -7px;
    overflow-x: hidden;
    overflow-y: scroll;

    .item {
      margin: 7px;
      float: left;
      color: #999999;
      font-size: 12px;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px 0 #999;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      .van-icon {
        font-size: 20px;
      }
    }
  }
}
</style>
