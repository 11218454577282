<template>
  <router-view :width="width" :height="height" :visitor-id="visitorId"/>
</template>

<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default {
  name: "App",
  data() {
    return {
      width: document.body.clientWidth,
      height: document.body.clientHeight,
      visitorId: "",
    }
  },
  async mounted() {
    const self = this;
    window.onresize = () => {
      self.width = document.body.clientWidth;
      self.height = document.body.clientHeight;
    };
    clearTimeout(window.DetectTimeId);
    await this.initVisitor();
  },
  methods: {
    async initVisitor() {
      const fpPromise = await FingerprintJS.load();
      this.visitorId = (await fpPromise.get()).visitorId;
      window._czc.push(['_setCustomVar', 'visitorId', this.visitorId, 1]);
    },
  }
}
</script>

<style lang="less">
html, body {
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 720px;
  font-size: 14px;
  position: relative;
}

.van-notify {
  min-height: 44px;
}
</style>
