<template>
  <BottomPopup v-model="show" :title="title">
    <div class="download">
      <div class="wrapper">
        <van-loading v-if="loading" size="55px" color="#ff5500" type="spinner" style="margin: 50px 0;"/>
        <div v-else-if="photos.length" class="image-box">
                    <span v-for="(photo, index) in photos" :key="photo.id">
                        <img :src="photo.thumb.url" alt=""/>
                        <van-button @click="remove(index)" type="danger" size="mini" icon="delete"
                                    :block="true">移除</van-button>
                    </span>
        </div>
        <EfotoEmpty v-else description="暂无选择图片" style="margin-top: 25px;"/>
      </div>
      <div v-if="photos.length">
        <div style="color:#999;margin-bottom:15px;line-height:22px;">
          <div v-if="album['archived']" style="margin-bottom: 10px;">
            <van-icon name="info-o" style="float: left; margin: 4px 4px 0 0;"/>
            当前高清图，原图数据皆已归档。提交下载后，系统将在12小时内准备就绪并发送消息通知您下载
          </div>
          <template v-if="hasNoFree">
            <div>
              <van-icon name="info-o" style="float: left; margin: 4px 4px 0 0;"/>
              已打赏的图片无需重复打赏，会自动扣减相应费用。完成支付后请耐心等待几秒，系统会自动开始下载。如遇到无法下载的请点击跳转至
              <router-link :to="{name: 'UCenter.Reward'}">打赏记录</router-link>
              进行下载
            </div>
            <div v-if="noOrigins.length" style="margin-top: 10px;">
              <van-icon name="info-o" style="float: left; margin: 4px 4px 0 0;"/>
              还有 <b style="color:#ff5500;">{{ noOrigins.length }}</b>
              张原图未准备就绪，打赏原图后可先立即下载高清图，系统会在原图就绪后通知，您可通过
              <router-link :to="{name: 'UCenter.Reward'}">打赏记录</router-link>
              重新下载所有原图
            </div>
            <div style="margin-top: 10px;">
              <van-icon name="info-o" style="float: left; margin: 4px 4px 0 0;"/>
              若有任何疑问请发送邮件至
              <a href="mailto:service@efoto.me">service@efoto.me</a>
              或关注微信号
              <a href="javascript:void(0)" @click="showMlszpCode">马拉松照片</a>
              留言
            </div>
          </template>
        </div>
        <SaleDownload :type="1" :album="album" :photos="photoIds" :config="salePolicy['hd_mark']" :user="user"/>
        <SaleDownload :type="2" :album="album" :photos="photoIds" :config="salePolicy['hd_no_mark']" :user="user"/>
        <SaleDownload :type="3" :album="album" :photos="photoIds" :config="salePolicy['original']" :user="user"/>
      </div>
    </div>
    <MlszpQRCode ref="mlszp" />
  </BottomPopup>
</template>

<script>
import api from "../../../utils/api";
import func from "../../../utils/func";
import SaleDownload from "./SaleDownload";
import DelayLoading from "../../../comps/DelayLoading";
import BottomPopup from "../../../comps/BottomPopup";
import MlszpQRCode from "../../../comps/MlszpQRCode";
import EfotoEmpty from "../../../comps/EfotoEmpty";

export default {
  name: "DownloadPg",
  components: {EfotoEmpty, MlszpQRCode, BottomPopup, SaleDownload},
  mixins: [DelayLoading],
  props: {album: Object},
  data() {
    return {
      show: false,
      user: {},
      photos: [],
      salePolicy: this.album.config["sale_policy"],
    }
  },
  computed: {
    title() {
      return `共选择图片 ${this.photos.length} 张`;
    },
    photoIds() {
      return func.getByKey(this.photos, "id");
    },
    hasNoFree() {
      return this.salePolicy["hd_mark"].init > 0
          || this.salePolicy["hd_no_mark"].init > 0
          || this.salePolicy["original"].init > 0;
    },
    noOrigins() {
      return this.photos.filter(photo => !photo.origin);
    }
  },
  methods: {
    open(photos) {
      const self = this;
      this.show = true;
      this.withLoading(500, async () => {
        let args = {
          ids: photos.join(","),
          page_size: photos.length,
        };
        self.user = await self.$store.dispatch("user/detail");
        self.photos = await api.v1.album.photos.search(this.album.id, args);
      });
    },
    remove(index) {
      this.photos.splice(index, 1);
    },
    showMlszpCode() {
      this.$refs.mlszp.open();
    }
  }
}
</script>

<style lang="less">
.download {
  padding: 0 16px 16px 16px;

  .wrapper {
    height: 160px;
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    margin-bottom: 15px;

    .image-box {
      display: flex;
      display: -webkit-flex;

      span {
        margin-right: 10px;
      }

      img {
        height: 120px;
      }
    }

    .van-empty {
      padding: 5px 0;
    }

    .van-empty__image {
      width: 60px;
      height: 60px;
    }
  }

  .van-row {
    font-size: 14px;
    line-height: 28px;
    margin: 16px 0;
  }
}
</style>
