<template>
    <van-tabs v-model="value" v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </van-tabs>
</template>

<script>
    import StateMixin from "./StateMixin";

    export default {
        name: "StateTabs",
        mixins: [StateMixin]
    }
</script>
