<template>
    <div class="book">
        <van-form>
            <van-field
                v-model="form.name"
                :required="true" label="联系姓名"
                placeholder="请输入联系人姓名"
            />
            <van-field
                v-model="form.mobile"
                :required="true" label="联系电话"
                placeholder="请输入联系人手机号"
            />
            <Area v-model="extend.area" label="举办城市" title="请选择活动举办城市"/>
            <DatetimePicker v-model="extend.time" label="拍摄日期" title="请选择预估的拍摄日期"/>
            <van-field
                v-model="form.comment"
                :required="false" label="需求简述"
                placeholder="简述告知我们您的拍摄需求"
                type="textarea" :autosize="true" rows=5
            />
        </van-form>
        <div style="margin-top: 20px;">
            <SaveButton title="提交预约" :action="submit"/>
        </div>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import SaveButton from "../../../comps/SaveButton";
    import Area from "../../../comps/Area";
    import DatetimePicker from "../../../comps/DatetimePicker";
    import func from "../../../utils/func";

    export default {
        name: "Book",
        props: {album: Object},
        components: {DatetimePicker, Area, SaveButton},
        data() {
            return {
                form: {
                    name: "",
                    mobile: "",
                    comment: "",
                    extend: {},
                },
                extend: {
                    time: 0,
                    area: ["", ""],
                },
            }
        },
        methods: {
            submit() {
                if (!this.validate())
                    return this.$notify({type: "danger", message: "请先完善带*号的必填项"});
                else if (!func.isMobile(this.form.mobile))
                    return this.$notify({type: "danger", message: "您输入的手机号码不正确"});
                this.form.extend.area = this.extend.area[1];
                this.form.extend.time = func.tsFormat(this.extend.time, "YYYY-MM-DD");
                return api.v1.album.clue(this.album.id, this.form).then(() => {
                    this.$notify({type: "success", message: "预约成功"});
                    this.extend = {time: 0, area: ["", ""]};
                    this.form = {name: "", mobile: "", comment: "", extend: {}};
                });
            },
            validate() {
                return this.form.name && this.form.mobile
                    && this.extend.time && this.extend.area[0];
            }
        }
    }
</script>

<style lang="less">
    .book {
        margin: 15px;

        .van-form {
            color: #ffffff;
            padding: 20px 10px;
            background: #fff;
            border-radius: 4px;

            .van-cell {
                padding: 16px;

                .van-icon {
                    font-size: 18px;
                    margin-right: 10px;
                }
            }
        }
    }
</style>
