<template>
    <van-button
        :loading="saving" :loading-text="title" loading-size="16px" @click="save"
        :block="true" :round=true icon="success" type="primary" v-bind="$attrs" v-on="$listeners">
        {{title}}
    </van-button>
</template>

<script>
    export default {
        name: "SaveButton",
        props: {
            title: {
                type: String,
                default: "保存"
            },
            action: {
                type: Function,
                required: true,
            }
        },
        data() {
            return {
                saving: false
            }
        },
        methods: {
            async save() {
                this.saving = true;
                await this.action();
                this.saving = false;
            }
        }
    }
</script>
