import api from "../utils/api";

export default {
	namespaced: true,
	state: {
		provinces: [],
		cities: [],
		districts: [],
	},
	mutations: {
		syncProvinces(state, provinces) {
			state.cities = provinces;
		},
		syncCities(state, cities) {
			state.cities = cities;
		},
		syncDistricts(state, districts) {
			state.districts = districts;
		}
	},
	actions: {
		level(ctx, level) {
			if (level === 1) return ctx.dispatch("provinces");
			else if (level === 2) return ctx.dispatch("cities");
			else return ctx.dispatch("districts");
		},
		async provinces(ctx) {
			if (ctx.state.provinces.length)
				return ctx.state.provinces;
			let res = await api.v1.area.all(1);
			ctx.commit("syncProvinces", res);
			return res;
		},
		async cities(ctx) {
			if (ctx.state.cities.length)
				return ctx.state.cities;
			let res = await api.v1.area.all(2);
			ctx.commit("syncCities", res);
			return res;
		},
		async districts(ctx) {
			if (ctx.state.districts.length)
				return ctx.state.districts;
			let res = await api.v1.area.all(3);
			ctx.commit("syncDistricts", res);
			return res;
		}
	},
}
