<template>
    <van-popup v-model="show" position="bottom" style="height: 100%" v-on="$listeners"
               :closeable="true" :close-on-click-overlay="false" :lazy-render="true">
        <div class="puzzle-show">
            <div class="header">长按图片保存</div>
            <van-loading v-if="loading" type="spinner" size="60px" color="#ff5500" :vertical="true"/>
            <ResourceImg v-if="resource" v-show="!loading" :id="resource" :style="{maxHeight: maxHeight+'px'}"/>
        </div>
    </van-popup>
</template>

<script>
    import ResourceImg from "../../../comps/ResourceImg";
    import DelayLoading from "../../../comps/DelayLoading";

    export default {
        name: "PuzzleShow",
        components: {ResourceImg},
        mixins: [DelayLoading],
        data() {
            return {
                show: false,
                resource: "",
            }
        },
        computed: {
            maxHeight() {
                return document.documentElement.clientHeight - 50;
            }
        },
        methods: {
            open(loadFunc) {
                const self = this;
                self.resource = "";
                self.show = true;
                this.withLoading(500, () => {
                    return loadFunc().then(res => self.resource = res.id);
                });
            }
        }
    }
</script>

<style lang="less">
    .puzzle-show {
        width: 100%;
        height: 100%;
        font-size: 14px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;

        .header {
            top: 0;
            width: 100%;
            height: 50px;
            color: #ffffff;
            line-height: 54px;
            text-align: center;
            background: #000;
            position: absolute;
        }

        img {
            margin-top: 50px;
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>
