<template>
    <div>
        <van-field
            v-model="value"
            :required="true" :readonly="true" right-icon="arrow"
            :label="label" :placeholder="title" @click="()=>this.show=true"/>
        <van-popup
            :close-on-click-overlay="false"
            v-model="show" position="bottom">
            <van-datetime-picker
                :type="type" :title="title"
                :min-date="minDate" :max-date="maxDate"
                @cancel="cancel" @confirm="confirm"/>
        </van-popup>
    </div>
</template>

<script>
    import func from "../utils/func";

    export default {
        props: {
            type: {
                type: String,
                default: "date"
            },
            label: String,
            title: String,
            minDate: {
                type: Date,
                default: () => {
                    return new Date();
                }
            },
            maxDate: {}
        },
        data() {
            return {
                show: false,
            };
        },
        computed: {
            value: {
                get() {
                    if (this.$attrs.value === 0) return "";
                    return func.dateFormat(new Date(this.$attrs.value * 1000), "YYYY-MM-DD");
                },
                set(value) {
                    this.$emit("input", Math.floor((new Date(value)).getTime() / 1000));
                }
            }
        },
        methods: {
            cancel() {
                this.show = false;
            },
            confirm(e) {
                this.value = e;
                this.show = false;
            }
        }
    };
</script>
