import Vue from "vue";
import {
   Area,
   Button,
   Cell,
   CellGroup,
   Checkbox,
   Circle,
   Col,
   Collapse,
   CollapseItem,
   DatetimePicker,
   Dialog,
   Divider,
   DropdownItem,
   DropdownMenu,
   Empty,
   Field,
   Form,
   Icon,
   Image,
   ImagePreview,
   List,
   Loading,
   NavBar,
   Notify,
   Overlay,
   Popup,
   Progress,
   Row,
   Stepper,
   Sticky,
   Swipe,
   SwipeItem,
   Tab,
   Tabs,
   Uploader
} from "vant";
import VueCode from "@basic/vue-code";
import "@basic/vue-code/dist/vue-code.css";
import VueCropper from "@basic/vue-cropper";
import "@basic/vue-cropper/dist/vue-cropper.css";
import VirtualCollection from '@basic/vue-virtual-collection'

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dialog);
Vue.use(Circle);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(Stepper);
Vue.use(Divider);
Vue.use(NavBar);
Vue.use(Progress);
Vue.use(Area);
Vue.use(Form);
Vue.use(Field);
Vue.use(Notify);
Vue.use(Checkbox);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Cell);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ImagePreview);
Vue.use(DatetimePicker);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Image);
Vue.use(Button);
Vue.use(Sticky);

Vue.use(VueCode);
Vue.use(VueCropper);
Vue.use(VirtualCollection);
