import Vue from 'vue';
import VueRouter from 'vue-router';

import api from "../utils/api";
import func from "../utils/func";
import enums from "../utils/enums";

import Home from "../pages/Home";
import Live from "../pages/Live";
import UCenter from "../pages/UCenter";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/:id",
		name: "Live",
		component: Live,
		redirect: {name: "Live.Index"},
		children: require("./live").default,
	},
	{
		path: "/ucenter",
		name: "UCenter",
		component: UCenter,
		redirect: {name: "UCenter.Index"},
		children: require("./ucenter").default,
	}
];

let router = new VueRouter({
	routes: routes,
	mode: "history"
});

router.beforeEach(async (to, from, next) => {
	let ticket = to.query.ticket;
	if (func.stringStrict(ticket)) {
		await api.v1.auth.loginSubmit({
			ticket_id: ticket,
			platform: enums.Platform
		});
	}
	await func.wechatLogin(to.query) ? next() : null;
});

export default router
