<template>
  <div class="advert" v-if="data">
    <a :href="url" target="_blank">
      <ResourceImg :id="data.cover"/>
    </a>
    <div style="padding:8px 10px;">
      <div class="name">{{ data.config.name }}</div>
      <div class="desc" v-if="data.config.desc">{{ data.config.desc }}</div>
    </div>
  </div>
</template>

<script>
import ResourceImg from "../../../comps/ResourceImg";

export default {
  name: "Advert",
  components: {ResourceImg},
  props: {
    data: Object
  },
  computed: {
    url() {
      return `https://material.api.ing-sports.com/v1/adverts/${this.data.id}`;
    }
  }
}
</script>

<style scoped lang="less">
.advert {
  margin-top: 3px;
  line-height: 20px;
  background: #ffffff;


  img {
    width: 100%;
    display: block;
  }

  .name {
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .desc {
    color: #999;
    height: 41px;
    font-size: 12px;
    overflow: hidden;
  }
}
</style>