import "babel-polyfill";

import Vue from 'vue';
import App from "./App.vue";
import store from "./store";
import router from "./router";

require("./utils/vant");
require("./utils/filter");

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app');
