<template>
  <van-popup class="mlszp" v-model="show"
             :round="true" :close-on-click-overlay="false" :closeable="true">
    <div>长按识别关注微信</div>
    <img src="/mlszp.jpg" alt=""/>
  </van-popup>
</template>

<script>
export default {
  name: "MlszpQRCode",
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true;
    }
  }
}
</script>

<style scoped lang="less">
.mlszp {
  padding: 16px;
  font-size: 16px;
  width: 240px;
  text-align: center;

  img {
    max-width: 100%;
    margin-top: 20px;
  }
}
</style>