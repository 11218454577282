import api from "../utils/api";
import func from "../utils/func";
import * as dd from 'dingtalk-jsapi';

let dingCached = {}; // 配置缓存

export default {
	data() {
		return {
			url: window.location.href,
		}
	},
	methods: {
		async initDingTalk() {
			if (func.isDefined(dingCached[this.url])) return;
			dingCached[this.url] = {}; // set cache first
			let res = await api.v1.dingTalk.jsapi(this.url);
			res.jsApiList = ['biz.util.share'];
			dd.config(res);
			dd.error(function (err) {
				alert('dd error: ' + JSON.stringify(err));
			});
		},
		shareDingTalk(config, success) {
			const self = this;
			this.callWidthDingTalk(async () => {
				await self.initDingTalk();
				dd.ready(function () {
					dd.biz.navigation.setRight({
						show: true,
						control: true,
						onSuccess: function () {
							dd.biz.util.share({
								type: 0,
								url: self.url,
								title: config["title"] + '|图片直播',
								image: func.shareCover(config["cover"]),
								content: config["desc"] ? config["desc"] : config["title"],
								onSuccess: function () {
									success("onDingTalkShare", self.url);
								},
								onFail: function (err) {
									alert('dd error: ' + JSON.stringify(err));
								}
							});
						},
						onFail: function (err) {
							alert('dd error: ' + JSON.stringify(err));
						}
					});
				});
			});
		},
		callWidthDingTalk(callback) {
			if (dd.env.platform !== 'notInDingTalk') callback();
		},
	}
}