<template>
  <div class="helper">
    <NavBar title="帮助中心"/>
    <LoadingList :load-func="load">
      <template slot-scope="{result}">
        <van-collapse v-model="actives" :border="false">
          <template v-for="(item, index) in result.data">
            <van-collapse-item :key="item.id" is-link>
              <template #title>{{ index + 1 }}. {{ item.info.title }}</template>
              <div style="text-indent:12px;" v-html="item.info.content"></div>
            </van-collapse-item>
          </template>
        </van-collapse>
      </template>
    </LoadingList>
  </div>
</template>

<script>
import api from "../utils/api";
import NavBar from "./ucenter/comps/NavBar";
import LoadingList from "../comps/LoadingList";

export default {
  name: "Helper",
  components: {LoadingList, NavBar},
  data() {
    return {
      actives: [],
    }
  },
  methods: {
    load(pageNo) {
      return api.v1.help({page_no: pageNo, page_size: 20});
    }
  }
}
</script>

<style lang="less">
.helper {
  padding-top: 44px;

  p {
    margin: 0
  }

  .van-collapse-item {
    .van-cell {
      background: #222;
      color: #fff;
    }

    .van-cell::after {
      border-color: transparent;
    }
  }

  .van-collapse-item::after {
    border: none;
  }


  .van-collapse-item__content {
    line-height: 22px;
    font-size: 12px;
    color: #eeeeee;
    background: #000000;
  }
}
</style>