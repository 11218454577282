<template>
    <div class="preview">
        <van-image-preview
            v-model="show" @change="change" closeIcon="cross"
            :images="images" :start-position="index" :closeable="true" :loop="false">
            <template v-slot:index>
                <div>图片名称：{{photos[group].group[index].data.info["file_name"]}}</div>
                <div>拍摄时间：{{photos[group].group[index].data.info["catch_time"] | tsFormat}}</div>
            </template>
            <template v-slot:cover>
                <div class="person">
                    <template v-for="(person, index2) in photos[group].group[index].data['person']">
                        <ResourceImg :id="person.avatar" :key="index2" @click="match(person)" resize="m_8,w_60,h_60"/>
                    </template>
                </div>
                <van-row v-if="album['archived']">
                    <van-col span="8">
                      <div v-if="!stars[current.id]" class="button" @click="star"><van-icon name="star-o"/>收藏</div>
                      <div v-else class="button" style="color: #ff5500;" @click="unStar"><van-icon name="star"/>收藏</div>
                    </van-col>
                    <van-col span="8">
                      <div class="button" @click="share"><van-icon name="share-o"/>分享</div>
                    </van-col>
                    <van-col span="8">
                      <div class="button" @click="download"><van-icon name="down"/>下载</div>
                    </van-col>
                </van-row>
                <van-row v-else>
                    <van-col span="9" style="padding: 0 15px;">
                        <van-button @click="viewHd" type="primary" size="small" :block="true" :round="true">{{hdLabel}} {{hdSize | bytes}}</van-button>
                    </van-col>
                    <van-col span="5">
                        <div v-if="!stars[current.id]" class="button" @click="star"><van-icon name="star-o"/>收藏</div>
                        <div v-else class="button" style="color: #ff5500;" @click="unStar"><van-icon name="star"/>收藏</div>
                    </van-col>
                    <van-col span="5">
                        <div class="button" @click="share"><van-icon name="share-o"/>分享</div>
                    </van-col>
                    <van-col span="5">
                        <div class="button" @click="download"><van-icon name="down"/>下载</div>
                    </van-col>
                </van-row>
            </template>
        </van-image-preview>
        <LoginPopup ref="loginPopup"/>
        <PuzzleShow ref="puzzleShow"/>
        <DownloadPg ref="downloadPg" :album="album"/>
    </div>
</template>

<script>
    import api from "../../../utils/api";
    import func from "../../../utils/func";
    import ResourceImg from "../../../comps/ResourceImg";
    import DelayLoading from "../../../comps/DelayLoading";
    import PuzzleShow from "./PuzzleShow";
    import DownloadPg from "./DownloadPg";
    import LoginPopup from "../../../comps/LoginPopup";

    export default {
        name: "Preview",
        components: {LoginPopup, DownloadPg, PuzzleShow, ResourceImg},
        mixins: [DelayLoading],
        props: {
            album: Object,
            photos: Array
        },
        data() {
            return {
                show: false,
                group: 0,
                index: 0,
                hdUrls: {},
                stars: {},
            }
        },
        computed: {
            images() {
                let images = [];
                this.photos[this.group].group
                    .forEach(photo => {
                        let hdUrl = this.hdUrls[photo.data.id];
                        if (func.isDefined(hdUrl)) images.push(hdUrl);
                        else images.push(photo.data["thumb"]["url"]);
                    });
                return images;
            },
            current() {
                return this.photos[this.group].group[this.index].data;
            },
            hdSize() {
                let rl = parseInt(this.album.config['flow_policy']['resize_long']);
                let sl = parseInt(this.album.config['sale_policy']['original']["init"]);
                return !this.current.origin || (!rl && sl <= 0)
                    ? this.current["foto"]["size"]
                    : this.current["foto"]["size"] / (6400/rl);
            },
            hdLabel() {
                let rl = parseInt(this.album.config['flow_policy']['resize_long']);
                let sl = parseInt(this.album.config['sale_policy']['original']["init"]);
                let label = (!rl && sl <= 0) ? "原图" : "高清";
                return this.hdUrls[this.current.id] ? `已是${label}` : `查看${label}`;
            }
        },
        mounted() {
            this.$store.dispatch("photo/loadStars", this.album.id).then(res => this.stars = res);
        },
        methods: {
            open(group, index) {
                this.show = true;
                this.group = group;
                this.change(index);
            },
            close() {
                this.show = false;
            },
            match(person) {
                let params = {
                  id: this.album.id, type: "2",
                  value: person["person_ids"].join(",")
                };
                this.$router.push({name: "Live.Match", params: params}).catch(() => null).finally(() => this.show = false);
            },
            star() {
                const self = this;
                this.$refs.loginPopup.open(() => {
                    let args = {photo_id: self.current.id, album_id: self.album.id};
                    api.v1.star.create(args).then(res => self.$set(self.stars, self.current.id, res.id));
                });
            },
            unStar() {
                const self = this;
                this.$refs.loginPopup.open(() => {
                    api.v1.star.delete(self.stars[self.current.id]).then(() => self.$set(self.stars, self.current.id, null));
                });
            },
            share() {
                const self = this;
                this.$refs.puzzleShow.open(() => {
                    return api.v1.album.puzzle.simple(self.album.id, [self.current.id]);
                });
            },
            async viewHd() {
                if (this.album["archived"]) return; // 已归档
                if (this.hdUrls[this.current.id]) return;
                let urls = await api.v1.album.photos.hdURL(this.album.id, [this.current.id]);
                for (let id in urls) this.$set(this.hdUrls, id, urls[id]);
            },
            download() {
                this.$refs.loginPopup.open(() => {
                    this.$refs.downloadPg.open([this.current.id]);
                });
            },
            change(index) {
                this.index = index;
                if (this.album["auto_hd"]) {
                  this.viewHd();
                }
            }
        }
    }
</script>

<style lang="less">
    .preview {
        letter-spacing: 1px;

        .person {
            text-align: center;
            overflow-x: auto;
            margin-bottom: 30px;

            img {
                width: 50px;
                margin: 5px;
                border-radius: 100%;
                box-shadow: 0 0 3px 1px #777;
            }
        }

        .van-image-preview {
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 720px;
            color: #ffffff;
        }

        .van-image-preview__index {
            width: 100%;

            div {
                font-size: 12px;
                padding-left: 16px;
            }
        }

        .van-image-preview__cover {
            top: auto;
            width: 100%;
            bottom: 20px;
            font-size: 14px;

            button {
                background: transparent;
            }

            .button {
                width: 60px;
                margin: 0 auto;
                height: 32px;
                line-height: 32px;

                .van-icon {
                    float: left;
                    font-size: 16px;
                    margin-top: 7px;
                    margin-right: 3px;
                }
            }
        }

        .van-image-preview__overlay {
            background: #000000;
        }
    }
</style>
