import Vue from "vue";
import func from "./func";

Vue.filter("substr", (str, from, len) => {
    return str.substr(from, len);
});

Vue.filter("tsFormat", (ts, format) => {
    if (!func.isDefined(format)) {
        format = "YYYY-MM-DD HH:mm";
    }
    return func.tsFormat(ts, format);
});

Vue.filter("maskString", (str, from, length) => {
    return func.maskString(str, from, length)
});

Vue.filter("formatCNY", func.formatCNY);
Vue.filter("formatCNF", func.formatCNF);

Vue.filter("bytes", func.bytesAuto);
