<template>
  <div ref="history" style="padding-top: 44px; background: #000;">
    <NavBar title="查询记录"/>
    <SimpleLoading v-if="loading"/>
    <EfotoEmpty v-else-if="!result.total" description="查无查询记录"/>
    <div v-else v-for="id in result.album_ids" :key="id">
      <div v-if="result.albums[id]" class="album_history">
        <div class="title">
          <van-icon name="fire"/>
          {{ result.albums[id].info.name }}
        </div>
        <div class="items">
          <div v-for="item in result.history[id]" :key="item.id">
            <div class="item" :style="{width: width+'px', height: width+'px'}" :key="item.id" @click="toMatch(item)">
              <ResourceImg v-if="item.info.type===2" :id="getAvatar(item)"/>
              <div v-else style="width: 100%; text-align: center; line-height: 18px;">
                <van-icon name="user-o"/>
                <div>{{ item.info.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../utils/api";
import func from "../../utils/func";
import NavBar from "./comps/NavBar";
import SimpleLoading from "../../comps/SimpleLoading";
import DelayLoading from "../../comps/DelayLoading";
import ResourceImg from "../../comps/ResourceImg";
import EfotoEmpty from "../../comps/EfotoEmpty";

export default {
  name: "Search",
  components: {EfotoEmpty, ResourceImg, SimpleLoading, NavBar},
  mixins: [DelayLoading],
  data() {
    return {
      result: {
        total: 0,
        albums: {},
        history: {},
        album_ids: [],
      },
      avatars: {},
    }
  },
  computed: {
    width() {
      return func.computedColWidth({
        gutter: 14, count: 5, range: [60, 80],
        width: this.$refs.history.clientWidth - 30 - 16,
      })
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.withLoading(0, async () => {
        let promises = [];
        this.result = await api.v1.history.index();
        console.log(this.result);
        for (let albumId in this.result.history) {
          promises.push(this.loadPersons(albumId, this.result.history[albumId]));
        }
        await Promise.all(promises);
      })
    },
    getAvatar(item) {
      if (!this.avatars[item.info.album_id]) return "";
      return this.avatars[item.info.album_id][item.info.value];
    },
    toMatch(item) {
      let params = {
        id: item.info.album_id,
        type: item.info.type,
        value: item.info.value
      };
      this.$router.push({name: "Live.Match", params: params}).catch(() => null); // 屏蔽同地址错误
    },
    loadPersons(albumId, items) {
      const self = this;
      let personIds = [];
      items.forEach(item => {
        if (item.info.type === 2) {
          personIds.push(item.info.value);
        }
      });
      let args = {ids: personIds.join(","), page_size: personIds.length};
      return api.v1.album.persons(albumId, args).then(res => self.avatars[albumId] = res);
    },
  }
}
</script>

<style scoped lang="less">
.album_history {
  cursor: pointer;
  padding: 10px 15px;
  line-height: 24px;
  margin: 8px;
  color: #999999;
  background: #222;
  font-size: 12px;

  .title {
    margin: 5px 0;
    color: #eeeeee;
    font-size: 14px;
    font-weight: bold;

    .van-icon {
      float: left;
      margin: 5px 5px 0 0;
    }
  }

  .items {
    height: 100%;
    margin: 0 -7px;
    overflow-x: hidden;

    .item {
      margin: 7px;
      float: left;
      color: #999999;
      font-size: 12px;
      cursor: pointer;
      border-radius: 4px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px 0 #999;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      .van-icon {
        font-size: 20px;
      }
    }
  }
}


.van-empty {
  background: #000;
}
</style>