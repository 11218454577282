<template>
  <van-empty image="/logo2.png" v-bind="$attrs">
    <slot></slot>
  </van-empty>
</template>

<script>
export default {
  name: "EfotoEmpty"
}
</script>