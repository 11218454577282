<template>
  <div class="login-code">
    <van-loading v-if="loading" size="61px" color="#ff5500" type="spinner" style="margin: 66px 0;"/>
    <template v-else>
      <img :src="code.url" alt="" width="150" height="150"/>
      <div style="margin: 3px 0;">{{ label }}</div>
    </template>
  </div>
</template>

<script>
import api from "../utils/api";
import func from "../utils/func";
import enums from "../utils/enums";
import store from "../utils/store";
import DelayLoading from "./DelayLoading";

export default {
  name: "LoginQRCode",
  props: {
    scene: String,
    label: String,
  },
  mixins: [DelayLoading],
  data() {
    return {
      code: {
        url: "",
        ticket: "",
      },
      params: {
        wx_ticket: "",
        platform: enums.Platform,
      },
      timeId: null,
    }
  },
  mounted() {
    const self = this;
    this.withLoading(500, () => {
      return api.v1.auth.wechatQRCode(this.scene)
          .then(res => {
            self.code = res;
            self.checkLogin();
          });
    });
  },
  destroyed() {
    clearTimeout(this.timeId);
  },
  methods: {
    async checkLogin() {
      let res = await api.v1.auth.wechatQRCodeProbe(this.code.ticket);
      if (!res.ready) this.timeId = func.setTimeout(2000, this.checkLogin);
      else {
        this.params.wx_ticket = this.code.ticket;
        let args = await store.get("wechat.auth");
        await api.v1.auth.loginSubmit(
            args ? Object.assign(args, this.params) : this.params
        );
        this.$emit("done"); // trigger done event
      }
    }
  }
}
</script>

<style scoped lang="less">
.login-code {
  color: #777777;
  text-align: center;
  letter-spacing: 1px;

  img {
    padding: 2px;
    margin-top: 15px;
    border-radius: 4px;
    border: 1px #999 solid;
  }
}
</style>
