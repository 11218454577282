<template>
    <div v-if="persons.length">
        <VirtualCollection
            v-on="$listeners" v-bind="$attrs"
            :style="scrollStyle" @scroll.stop="onScroll"
            :collection="persons" :width="width" :height="height"
            :cellSizeAndPositionGetter="cellSizeAndPositionGetter">
            <LogoImage slot="cell" slot-scope="props" @click="match(props.data)"
                       :key="props.data.id" :id="props.data.id" :src="avatarUrl(props.data.avatar)"/>
        </VirtualCollection>
        <div v-if="loading" style="text-align: center; padding: 10px 0;">
            <van-loading type="spinner" size="18px" color="#969799">努力加载中</van-loading>
        </div>
    </div>
    <van-loading
        v-else-if="loading" type="spinner" style="margin-top:60px;"
        size="60px" color="#ff5500" :vertical="true">努力加载中
    </van-loading>
    <EfotoEmpty v-else image="search" description="暂无人物"/>
</template>

<script>
    import api from "../../../utils/api";
    import func from "../../../utils/func";
    import DelayLoading from "../../../comps/DelayLoading";
    import LogoImage from "../../../comps/LogoImage";
    import EfotoEmpty from "../../../comps/EfotoEmpty";

    export default {
        name: "Persons",
        components: {EfotoEmpty, LogoImage},
        props: {
            album: Object,
            width: Number,
            height: Number,
            columns: Number,
            padding: Number,
            scrollable: Boolean,
        },
        mixins: [DelayLoading],
        data() {
            return {
                params: {
                    system: 1,
                    page_no: 1,
                    page_size: 120,
                },
                counter: 0,
                persons: [],
                offsetY: [],
                complete: false,
            }
        },
        computed: {
            personWidth() {
                let padding = (this.columns - 1) * this.padding;
                let noScrollWidth = this.width - func.getScrollWidth();
                return (noScrollWidth - padding) / this.columns;
            },
            scrollStyle() {
                return this.scrollable ? "overflow:scroll" : "overflow:hidden";
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            match(person) {
                let params = {
                  id: this.album.id, type: "2", value: person.id
                };
                this.$router.push({name: "Live.Match", params: params});
            },
            onScroll(e) {
                if (this.complete) return;
                let bottom = e.target.scrollHeight - e.target.scrollTop;
                if (bottom < e.target.clientHeight * 2) this.loadPersons();
            },
            avatarUrl(id) {
                let size = Math.ceil(this.personWidth);
                return func.imageUrl(id, {
                    resize: `m_8,w_${size},h_${size}`
                });
            },
            refresh() {
                this.pageNo = 1;
                this.persons = [];
                this.counter = 0;
                this.loading = false;
                this.complete = false;
                for (let i = 0; i < this.columns; i++)
                    this.offsetY[i] = 0;
                this.loadPersons();
            },
            loadPersons() {
                const self = this;
                if (this.loading) return;
                if (this.complete) return;
                this.withLoading(500, () => {
                    return api.v1.album.persons(self.album.id, self.params)
                        .then(res => {
                            let oneGroup = [];
                            self.params.page_no += 1;
                            for (let id in res) {
                                let data = {id: id, avatar: res[id]};
                                oneGroup.push(self.preparePerson(data, self.counter++));
                            }
                            if (oneGroup.length === 0) self.complete = true;
                            else {
                                self.persons.push({group: oneGroup});
                                if (self.pageSize === 0) self.complete = true;
                            }
                        });
                });
            },
            preparePerson(person, index) {
                let columnIndex = index % this.columns;
                this.offsetY[columnIndex] += this.personWidth + this.padding;
                return {
                    data: person,
                    index: index,
                    width: this.personWidth,
                    height: this.personWidth,
                    x: columnIndex * (this.personWidth + this.padding),
                    y: this.offsetY[columnIndex] - this.personWidth,
                }
            },
            cellSizeAndPositionGetter(item) {
                return {width: item.width, height: item.height, x: item.x, y: item.y}
            },
        }
    }
</script>
