<template>
  <BottomPopup v-model="show" title="EFOTO - 欢迎登录">
    <div style="height: 200px; overflow: hidden;">
      <LoginForm v-if="mobileType" @done="done"/>
      <div v-else>
        <LoginQRCode scene="visitor" :label="wechatLabel" @done="done"/>
      </div>
    </div>
    <div class="third-party">
      <van-button v-if="mobileType" @click="type='wechat'" icon="chat-o">使用微信扫码登录</van-button>
      <van-button v-else @click="type='mobile'" icon="phone-o">使用手机号码登录</van-button>
    </div>
  </BottomPopup>
</template>

<script>
import auth from "../utils/auth";
import func from "../utils/func";
import BottomPopup from "./BottomPopup";
import LoginQRCode from "./LoginQRCode";
import LoginForm from "./LoginForm";

export default {
  name: "LoginPopup",
  components: {LoginForm, LoginQRCode, BottomPopup},
  data() {
    return {
      show: false,
      type: "mobile",
      callback: null,
    }
  },
  computed: {
    mobileType() {
      return this.type === "mobile";
    },
    wechatLabel() {
      return func.isWeiXin() ? "长按识别二维码登录" : "使用微信扫码登录";
    }
  },
  methods: {
    async open(callback) {
      let guest = await auth.isGuest();
      if (guest === false) callback();
      else {
        this.show = true;
        this.callback = callback;
      }
    },
    done() {
      this.show = false;
      this.callback();
    }
  }
}
</script>

<style scoped lang="less">
.third-party {
  color: #ff5500;
  padding: 25px 0;
  font-size: 14px;
  text-align: center;

  .van-button {
    border: none;
    color: #ff5500;
  }
}
</style>
