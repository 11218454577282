import Index from "../pages/live/Index";
import Match from "../pages/live/Match";
import Helper from "../pages/Helper";

export default [
	{
		path: "",
		name: "Live.Index",
		component: Index,
	},
	{
		path: ":type/:value",
		name: "Live.Match",
		component: Match,
	},
	{
		path: "helper",
		name: "Live.Helper",
		component: Helper
	}
]
