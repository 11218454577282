import moment from "moment";
import api from "./api";
import auth from "./auth";
import store from "./store";
import enums from "./enums";

export default {
	tsFormat: (ts, format) => moment.unix(ts).format(format),
	dateFormat: (date, format) => moment.unix(date.getTime() / 1000).format(format),
	formatCNY: (value) => "￥" + value.toFixed(2),
	formatCNF: (value) => "￥" + (value / 100).toFixed(2),
	sleep: (time) => new Promise((resolve) => setTimeout(resolve, time)),
	isDev: () => process.env.NODE_ENV === "development",
	isProd: () => process.env.NODE_ENV === "production",
	isWeiXin: () => navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1,
	isMobile: (mobile) => /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/.test(mobile),
	isDefined: (value) => typeof value !== "undefined",
	setTimeout: (delay, callback) => setTimeout(callback, delay),
	setInterval: (delay, callback) => setInterval(callback, delay),
	getByKey(array, key) {
		let values = [];
		for (let i in array) {
			values.push(array[i][key]);
		}
		return values;
	},
	maskString(str, from, length) {
		let mask = "";
		for (let i = 0; i < length; i++) mask += "*";
		return str.substr(0, from) + mask + str.substr(from + length, str.length);
	},
	bytesAuto(value) {
		if (!value) return;
		let kb = (value / 1024).toFixed(0);
		let mb = (value / 1024 / 1024).toFixed(2);
		return mb >= 1 ? `${mb}M` : `${kb}K`;
	},
	imageUrl(id, args) {
		let parts = [];
		for (let key in args) {
			this.isDefined(args[key]) &&
			parts.push(`${key}=${args[key]}`)
		}
		return parts.length
			? `${process.env.VUE_APP_RESOURCE}/images/${id}?` + parts.join("&")
			: `${process.env.VUE_APP_RESOURCE}/images/${id}`;
	},
	shareCover(cover) {
		if (!cover) {
			cover = process.env.VUE_APP_LIVEH5 + "/share.png";
		}
		let url = cover.indexOf("//") !== -1;
		let http = cover.indexOf("http") !== -1;
		if (url && !http) cover = "http:" + cover;
		return url ? cover : this.imageUrl(cover, {resize: "m_8,w_300,h_300"});
	},
	imageSize(url) {
		let img = new Image();
		return new Promise((resolve, reject) => {
			img.src = url
			img.onload = function () {
				resolve({width: img.width, height: img.height});
			}
			img.onerror = reject;
		});
	},
	obj2Query(args) {
		let parts = [];
		for (let key in args) {
			parts.push(`${key}=${args[key]}`);
		}
		return parts.join("&")
	},
	query2Obj(string) {
		let object = {};
		let parts = string.split("&");
		parts.forEach(part => {
			let pairs = part.split("=");
			object[pairs[0]] = pairs[1];
		});
		return object;
	},
	maxBodyWith() {
		return Math.min(720, document.documentElement.clientWidth) - this.getScrollWidth();
	},
	getScrollWidth() {
		let div1 = document.createElement("div");
		let div2 = document.createElement("div");
		div1.style.cssText = "overflow-y:scroll; width:100px; visibility:hidden";
		div2.style.cssText = "overflow-y:hidden; width:100%; visibility:hidden";
		div1.appendChild(div2);
		document.body.appendChild(div1);
		let width1 = div1.offsetWidth;
		let width2 = div2.offsetWidth;
		document.body.removeChild(div1);
		return width1 - width2;
	},
	computedColWidth(cfg) {
		let width = cfg.width;
		let count = cfg.count;
		const {gutter, range} = cfg;
		let colWidth = (width - ((count - 1) * gutter)) / count;
		while (colWidth > range[1]) {
			count += 1;
			colWidth = (width - ((count - 1) * gutter)) / count;
		}
		while (colWidth < range[0]) {
			count -= 1;
			colWidth = (width - ((count - 1) * gutter)) / count;
		}
		return colWidth;
	},
	intStrict(value) {
		return this.isDefined(value) ? value : 0;
	},
	boolStrict(value) {
		return this.isDefined(value) ? value : false;
	},
	stringStrict(value) {
		return this.isDefined(value) ? value : "";
	},
	async wechatLogin(query) {
		if (!this.isWeiXin()) return true;
		let guest = await auth.isGuest();
		if (guest === false) return true;
		let exist = await store.get("wechat.auth");
		if (exist && exist.auth_id && exist.signature) return true;
		let authId = query.auth_id, signature = query.signature;
		if (authId && signature) {
			if (query['user_id']) {
				let args = {platform: enums.Platform};
				await api.v1.auth.loginSubmit(Object.assign(args, query));
			} else {
				await store.save("wechat.auth", {
					auth_id: authId, signature: signature,
				});
			}
			return true;
		} else {
			let ref = window.location.href;
			let res = await api.v1.auth.wechatRedirect(ref)
			window.location.href = res.url;
			return false;
		}
	},
}
