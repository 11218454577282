<template>
  <div class="home">
    <van-nav-bar>
      <div slot="left">
        <img src="../assets/logo.png" height="28" alt="EFOTO云摄影"/>
      </div>
      <div slot="right">
        <van-field
            v-model="keyword" placeholder="请输入活动名称" :label="null" right-icon="search" @click-right-icon="reload"
            style="padding:4px 10px;border:1px #ddd solid;border-radius:20px;background:#f3f3f3;font-size:12px;"/>
      </div>
    </van-nav-bar>
    <div :style="{height:wrapperHeight+'px'}" style="overflow-y: scroll;">
      <LoadingList ref="list" :load-func="load" empty-text="暂无搜索匹配相册">
        <template slot-scope="{result}">
          <div class="album" :style="{height: albumHeight+'px'}"
               v-for="item in result.data" :key="item.id" @click="toView(item)">
            <ResourceImg :id="item.config['rs_id']" resize="m_8,w_720,h_360"/>
            <div class="background">&nbsp;</div>
            <div class="name">{{ item.config.name }}</div>
          </div>
        </template>
      </LoadingList>
    </div>
  </div>
</template>

<script>
import api from "../utils/api";
import log from "../utils/log";
import func from "../utils/func";
import LoadingList from "../comps/LoadingList";
import ResourceImg from "../comps/ResourceImg";
import WechatJSSDK from "../comps/WechatJSSDK";
import DingTalkJSSDK from "../comps/DingTalkJSSDK";

export default {
  name: "Home",
  components: {ResourceImg, LoadingList},
  mixins: [WechatJSSDK, DingTalkJSSDK],
  props: {
    width: Number,
    height: Number
  },
  data() {
    return {
      keyword: "",
    }
  },
  computed: {
    albumHeight() {
      return (this.width - 24 - func.getScrollWidth()) / 2;
    },
    wrapperHeight() {
      return this.height - 50;
    }
  },
  mounted() {
    let cfg = {
      title: "EFOTO云摄影",
      desc: "专业图片直播服务平台|免费好用的照片直播平台",
      cover: process.env.VUE_APP_LIVEH5 + "/share.png",
    };
    let shared = (flag, url) => {
      log.image("album.shared", {album_id: this.album.id, flag: flag, url: url});
    };
    this.share(cfg, shared);
    this.shareDingTalk(cfg, shared);
  },
  methods: {
    load(page) {
      let args = {
        page_no: page,
        page_size: 10,
        name: this.keyword,
      };
      return api.v1.album.case(args);
    },
    reload() {
      this.$refs.list.reload();
    },
    toView(item) {
      window.location.href = item.config.link;
    }
  }
}
</script>

<style lang="less">
.home {
  .van-empty {
    background: #ffffff;
  }

  .van-nav-bar {
    height: 50px;
    z-index: 1002;
  }

  .van-nav-bar__content {
    height: 50px;
  }

  .van-nav-bar .van-icon {
    color: #fe5900;
    font-weight: bold;
  }

  .album {
    margin: 12px;
    cursor: pointer;
    background: #ddd;
    overflow: hidden;
    border-radius: 5px;
    position: relative;

    img {
      width: 100%;
      background: #ccc;
    }

    .name {
      bottom: 0;
      color: #ffffff;
      line-height: 32px;
      position: absolute;
      z-index: 1001;
      padding: 0 12px;
    }

    .background {
      bottom: 0;
      width: 100%;
      height: 32px;
      opacity: 0.35;
      background: #000000;
      position: absolute;
      z-index: 1000;
    }
  }
}
</style>
