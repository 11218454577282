import store from "../utils/store";

export default {
    props: {
        stateKey: {
            type: String,
            default: "",
        }
    },
    computed: {
        value: {
            get() {
                return this.$attrs.value;
            },
            set(value) {
                this.$emit("input", value);
                if (this.stateKey !== "") {
                    this.setState(this.stateKey, value);
                }
            }
        }
    },
    created() {
        if (this.stateKey !== "") {
            this.getState(this.stateKey).then(value => {
                if (value && this.$attrs.value !== value) this.value = value;
                this.$emit("ready", value);
            });
        }
    },
    methods: {
        getState(key) {
            return store.get(this.buildStateKey(key));
        },
        setState(key, value) {
            return store.save(this.buildStateKey(key), value);
        },
        buildStateKey(key) {
            return `${this.$route.name}.${key}`;
        }
    }
}
