<template>
    <van-popup
        v-model="show" v-bind="$attrs"
        position="bottom" :closeable="true" :close-on-click-overlay="false">
        <div class="title">{{title}}</div>
        <slot></slot>
    </van-popup>
</template>

<script>
    export default {
        name: "BottomPopup",
        props: {
            title: String,
        },
        computed: {
            show: {
                get() {
                    return this.$attrs.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .title {
        padding: 16px 0;
        font-size: 16px;
        text-align: center;
    }

    .van-popup--bottom {
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 720px;
    }

    .van-popup__close-icon {
        z-index: 10000;
    }
</style>
