<template>
  <div class="index" @scroll.passive="scroll">
    <div ref="header">
      <Banner :items="album['banners']"/>
      <Album :album="album"/>
    </div>
    <TabList :album="album" :scrollable="scrollable" :style="{minHeight: height+'px'}"/>
    <div class="navbar_ref" v-if="refUrl">
      <a :href="refUrl">
        <van-icon name="arrow-left" style="float:left;margin:3px;"/>
        返回</a>
    </div>
  </div>
</template>

<script>
import log from "../../utils/log";
import func from "../../utils/func";
import Album from "./comps/Album";
import Banner from "./comps/Banner";
import TabList from "./comps/TabList";
import WechatJSSDK from "../../comps/WechatJSSDK";
import DingTalkJSSDK from "../../comps/DingTalkJSSDK";

export default {
  name: 'Home',
  props: {
    album: Object,
    height: Number,
  },
  mixins: [WechatJSSDK, DingTalkJSSDK],
  components: {TabList, Album, Banner},
  data() {
    return {
      scrollable: false,
    }
  },
  computed: {
    refUrl() {
      return decodeURIComponent(func.stringStrict(this.$route.query.ref));
    }
  },
  mounted() {
    let shared = (flag, url) => {
      log.image("album.shared", {album_id: this.album.id, flag: flag, url: url});
    };
    this.share(this.album["config"]["share"], shared);
    this.shareDingTalk(this.album["config"]["share"], shared);
  },
  methods: {
    scroll(e) {
      this.scrollable = (e.target.scrollTop >= this.$refs.header.clientHeight);
    }
  }
}
</script>

<style scoped lang="less">
.index {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #000;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
}

.navbar_ref {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  margin: 0 auto;
  max-width: 720px;
  padding: 4px 8px;
  position: fixed;
  background: #fafafa;

  a {
    width: 66px;
    color: #ff5500;
    display: block;
    margin: 0 auto;
  }
}
</style>
