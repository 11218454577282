import api from "../utils/api";
import wx from 'weixin-js-sdk';
import func from "../utils/func";
import enums from "../utils/enums";

let weixinCached = {}; // 配置缓存

export default {
	data() {
		return {
			url: window.location.href,
			flag: enums.WechatFlag,
		}
	},
	methods: {
		async initWeiXin() {
			if (func.isDefined(weixinCached[this.url])) return;
			weixinCached[this.url] = {}; // set cache first
			let res = await api.v1.wechat.jsapi(this.flag, this.url);
			wx.config({
				debug: false,
				appId: res.app_id,
				timestamp: res.timestamp,
				nonceStr: res.nonce_str,
				signature: res.signature,
				jsApiList: [
					'onMenuShareAppMessage',
					'onMenuShareTimeline',
					'onMenuShareQQ',
					'onMenuShareWeibo',
				],
				openTagList: [
					'wx-open-subscribe',
					'wx-open-launch-weapp',
				],
			});
		},
		async pay(args, success) {
			const self = this;
			this.callWithWeiXin(async () => {
				await self.initWeiXin();
				wx.ready(() => wx.chooseWXPay({
					package: args.package,
					paySign: args.paySign,
					nonceStr: args.nonceStr,
					signType: args.signType,
					timestamp: args.timeStamp,
					success: success
				}));
			})
		},
		async share(config, success) {
			const self = this;
			this.callWithWeiXin(async () => {
				await self.initWeiXin();
				wx.ready(() => {
					wx.onMenuShareQQ(self.shareConfig("onMenuShareQQ", config, success));
					wx.onMenuShareWeibo(self.shareConfig("onMenuShareWeibo", config, success));
					wx.onMenuShareTimeline(self.shareConfig("onMenuShareTimeline", config, success));
					wx.onMenuShareAppMessage(self.shareConfig("onMenuShareAppMessage", config, success));
				});
			});
		},
		shareConfig(flag, config, success) {
			const self = this;
			return {
				title: config["title"] + '|图片直播',
				desc: config["desc"] ? config["desc"] : config['title'],
				link: self.url, type: "link",
				imgUrl: func.shareCover(config["cover"]),
				success: () => success(flag, self.url),
			}
		},
		callWithWeiXin(callback) {
			if (func.isWeiXin()) callback();
		}
	}
}
