<template>
  <wx-open-subscribe v-if="button && ready" :template="template" v-on="$listeners">
    <script type="text/wxtag-template" slot="style">
      <style>
        .subscribe-btn {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          height: 44px;
          margin: 0;
          padding: 0 12px;
          font-size: 14px;
          line-height: 1.2;
          text-align: center;
          border-radius: 22px;
          cursor: pointer;
          color: #fff;
          outline-color: transparent;
          background-color: #f50;
          border: 1px solid #f50;
        }
      </style>
    </script>
    <script type="text/wxtag-template">
      <button class="subscribe-btn">订阅上传图片通知</button>
    </script>
  </wx-open-subscribe>
  <wx-open-subscribe v-else-if="ready" :template="template" v-on="$listeners">
    <script type="text/wxtag-template" slot="style">
      <style>
        .subscribe-img {
          width: 100%;
        }
      </style>
    </script>
    <script type="text/wxtag-template">
      <img src="https://live.efoto.me/subscribe.png" class="subscribe-img" alt=""/>
    </script>
  </wx-open-subscribe>
</template>

<script>
import func from "../../../utils/func";
import WechatJSSDK from "../../../comps/WechatJSSDK";

export default {
  name: "Subscribe",
  props: {
    album: Object,
    button: Boolean,
    template: String,
  },
  mixins: [WechatJSSDK],
  data() {
    return {
      ready: false
    }
  },
  async mounted() {
    if (!func.isWeiXin()) return;
    if (this.album.closed) return;
    await this.initWeiXin();
    this.ready = true;
  },
}
</script>